import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import { ContentfulRichTextType } from "../types/contentful.types"
import { stringToPath } from "./string-path-convertor"

const options = (highlights) => ({
  renderText: (text) => {
    return text
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: () => {
      return null
    },
    [BLOCKS.HEADING_3]: () => {
      return null
    },
    [BLOCKS.QUOTE]: () => {
      return null
    },
    [BLOCKS.OL_LIST]: () => {
      return null
    },
    [BLOCKS.TABLE]: () => {
      return null
    },
    [BLOCKS.UL_LIST]: () => {
      return null
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      let str = children[0]
      if (typeof str !== "string") {
        str = children[0]["props"]["children"]
      }
      const id = stringToPath(str, true).replace(/[\W\d]/g, "");
      const highlight = highlights ? Object.entries(highlights).find(([key]) => key === id)?.[1] : false
      
      return (
        <li className={`text-[#202135] font-bold  text-[14px] mb-4 ${highlight? 'text-[#bb00b3]': ''}`}>
          <a
            className="cursor-pointer"
            onClick={() => {
              const scrollAmount = document.querySelector(`#${id}`)?.getBoundingClientRect().top;
              if (scrollAmount) {
                window.scrollBy({
                  top: scrollAmount - 150,
                  behavior: 'smooth',
                })
              }
            }}
          >{str}</a>
        </li>
      )
    },
  },
})

const ContentfulRichTextContentTable = ({
  raw,
  className,
  style,
  highlights
}: ContentfulRichTextType) => {
  return (
    <div className={className} style={style}>
      {renderRichText(raw, options(highlights))}
    </div>
  )
}

export default ContentfulRichTextContentTable

