import { graphql } from "gatsby"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import BlogCategories from "../components/blog/blog-categories"
import DownloadPopup from "../components/download-popup"
import Footer from "../components/footer"
import Menu from "../components/menu/menu"
import MenuMobile from "../components/menu/menu-mobile"
import SEO from "../components/seo"
import { BlogArticleType, ContentfulBlogArticleType } from "../types/blog-types"
import ContentfulRichText from "../utilities/contentful-rich-text"
import BlogAuthor from "../components/blog/blog-author"
import ContentfulRichTextContentTable from "../utilities/contentful-rich-text-content-table"
import BlogSharer from "../components/blog/blog-sharer"
import useQueryParams from "query-params-hook"
import BlogBreadCrumbs from "../components/blog/blog-bread-crumbs"
import { ZoomableImage, RelatedArticles } from "../components"
import AuthorFact from "../components/blog/AuthorFact"
import CarnivalCruisePopUp from "../components/CarnivalCruisePopUp"

export const query = graphql`
  query BlogArticleQuery($slug: String!, $categoryId: String) {
    contentfulBlogArticle(
      compose__page: { elemMatch: { slug: { eq: $slug } } }
    ) {
      updatedAt(formatString: "D MMMM, YYYY")
      publishedDate(formatString: "D MMMM, YYYY")
      id
      color
      category {
        name
        id
      }
      pixel {
        pixel
      }
      adjustLink {
        adjustLink
      }
      defaultAdjustLink {
        defaultAdjustLink
      }
      text {
        raw
        references {
          ... on ContentfulComponentSimpleText {
            __typename
            contentful_id
            id
            backgroundColor
            text {
              raw
              references {
                ... on ContentfulComponentYoutubeVideo {
                  id
                  contentful_id
                  __typename
                  youtubeUrl {
                    youtubeUrl
                  }
                }
                ... on ContentfulComponentImageLink {
                  id
                  __typename
                  contentful_id
                  title
                  linkToUrl
                  openInNewWindow
                  image {
                    localFile {
                      publicURL
                    }
                  }
                  linkToInnerPage {
                    slug
                  }
                }
              }
            }
            images {
							localFile {
              	childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on ContentfulFaQsSection {
            __typename
            id
            contentful_id
            title
            faqs {
              question
              answer {
                raw
              }
            }
          }
          ... on ContentfulComponentTakeAways {
            __typename
            id
            contentful_id
            text {
              raw
            }
          }
          ... on ContentfulAsset {
            __typename
            id
            contentful_id
            description
            mimeType
            file {
              url
              fileName
              contentType
            }
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          ... on ContentfulComponentSlotsTypes {
            __typename
            id
            contentful_id
            slots {
              id
              compose__page {
                slug
                title
              }
              categories {
                title
                id
              }
              gameIframeUrl
              longGameIframeUrl {
                longGameIframeUrl
              }
              slotInfoLink
              slotIcon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 40)
                  }
                }
                title
              }
              description {
                raw
              }
            }
          }
          ... on ContentfulSocialPost {
            id
            contentful_id
            __typename
            postType
            url {
              url
            }
          }
          ... on ContentfulComponentYoutubeVideo {
            id
            contentful_id
            __typename
            youtubeUrl {
              youtubeUrl
            }
          }
          ... on ContentfulComponentHtmlCode {
            id
            contentful_id
            __typename
            html {
              html
            }
          }
          ... on ContentfulInfoBoxes {
            id
            contentful_id
            __typename
            frameColor
            text {
              raw
            }
            title
            type
          }
        }
      }
      author {
        name
        description {
          raw
        }
        avatar {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 200)
            }
          }
        }
        expertType
        twitterProfileUrl {
          twitterProfileUrl
        }
        fact {
          name
          description {
            raw
          }
          avatar {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 200)
              }
            }
          }
          expertType
          twitterProfileUrl {
            twitterProfileUrl
          }
        }
      }
      image {
        title
        localFile {
          childImageSharp {
            gatsbyImageData(width: 240)
          }
        }
      }
      pageVersions {
        key
        url {
          url
        }
      }
      compose__page {
        title
        slug
        id
        impressionLink {
          impressionLink
        }
        seo {
          no_index
          no_follow
          keywords
          description
          title
          redirect302
          redirect301
          schemaJson {
            schemaJson
          }
          additionalSchemaJson {
            additionalSchemaJson
          }
          socialImage {
            url
          }
        }
      }
    }

    allContentfulBlogArticle(
      filter: {
        category: { id: { eq: $categoryId } }
        compose__page: { elemMatch: { slug: { nin: [null, $slug] } } }
      }
      limit: 4
      sort: { fields: compose__page___createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          color
          compose__page {
            title
            slug
          }
          image {
            title
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
const GENERAL_ADJUST_LINK = "https://app.adjust.com/rvv2at4_b0g74fs_96gei5l"

const BlogArticleTemplate = ({
  data: {
    contentfulBlogArticle: article,
    allContentfulBlogArticle: relatedArticle,
  },
  pageContext,
}: {
  location: any
  data: {
    contentfulBlogArticle: BlogArticleType
    allContentfulBlogArticle: ContentfulBlogArticleType["allContentfulBlogArticle"]
  }
  pageContext: any
}) => {
  const {
    page_version,
    campaign,
    campaign_id,
    adgroup,
    creative,
    taboola_click_id,
    ad_id,
    adset_id,
  } = useQueryParams()
  const [loading, setLoading] = useState(true)
  const [showDownloadPopup, setShowDownloadPopup] = useState<boolean>(false)
  const [shouldShowBanner, setShouldShowBanner] = useState(false)

  const finalAdjustLink = useMemo(() => {
    setLoading(false)
    if (article.pageVersions?.length && page_version) {
      const pageVersionUrl = article.pageVersions?.find(
        (v) => v.key === page_version
      )?.url.url
      return decodeURIComponent(pageVersionUrl ?? GENERAL_ADJUST_LINK)
    } else if (article.defaultAdjustLink && !page_version) {
      return decodeURIComponent(
        article.defaultAdjustLink
          ? article.defaultAdjustLink.defaultAdjustLink
          : GENERAL_ADJUST_LINK
      )
    }
  }, [page_version])

  const replaceHashed = useMemo(
    () => ({
      campaign_name: campaign,
      campaign_id,
      site: adgroup || adset_id,
      campaign_item_id: creative || adset_id,
      click_id: taboola_click_id,
      ad_id,
      adset_id: adset_id || adgroup,
    }),
    [
      campaign,
      campaign_id,
      adgroup,
      creative,
      taboola_click_id,
      ad_id,
      adset_id,
    ]
  )

  const [highlights, setHighlights] = useState({})
  const highlightItems = useCallback(() => {
    const heading = document.querySelectorAll(".article-content h2")

    heading.forEach((h) => {
      const rect = h.getBoundingClientRect()
      const inView =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      setHighlights((highlights) => ({
        ...highlights,
        [h.id]: rect.top < 0 || inView,
      }))
    })
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", highlightItems)

    return () => window.addEventListener("scroll", highlightItems)
  }, [])

  if (loading) {
    return null
  }

  return (
    <>
      <SEO
        data={article?.compose__page?.[0]?.seo}
        scripts={page_version && article.pixel ? [article.pixel.pixel] : []}
        shouldShowBanner={shouldShowBanner}
        setShouldShowBanner={setShouldShowBanner}
        smartBannerLink={page_version && finalAdjustLink}
        impressionLink={article?.compose__page?.[0]?.impressionLink?.impressionLink}
      />
      {/* <CarnivalCruisePopUp onClick={() => setShowDownloadPopup(true)} /> */}

      <DownloadPopup
        onCloseClick={() => setShowDownloadPopup(false)}
        show={showDownloadPopup}
      />

      <div
        className={`app-container blog-container ${
          shouldShowBanner ? "show-banner" : ""
        }`}
      >
        <MenuMobile
          light_version={page_version}
          shouldShowBanner={shouldShowBanner}
        />
        <Menu
          light_version={page_version}
          hideOnScroll={false}
          onDownloadClick={() => setShowDownloadPopup(true)}
        />

        <main>
          {page_version || !article.category ? (
            <div className="blog-categories-container light-version"></div>
          ) : (
            <BlogCategories activeCategory={article.category.name} />
          )}
          <div className="blog-article-page ">
            <div
              className="article-page-top-info py-10"
              style={{ backgroundColor: article.color }}
            >
              <div className="article-page-top-info-container flex xl:justify-between xl:items-center">
                <div className="flex flex-col space-y-10 xl:pl-[60px]">
                  {!!article.category && (
                    <BlogBreadCrumbs
                      article={article}
                      clickable={!page_version}
                    />
                  )}
                  <h1 className="font-bold text-[24px] leading-[28px] xl:text-[48px] xl:leading-[50px] max-w-[680px]">
                    {article.compose__page[0].title}
                  </h1>
                  {article.author &&
                    <AuthorFact author={article.author} updatedAt={article.updatedAt} />
                  }
                  <div className="block xl:hidden">
                    <ZoomableImage
                      id="large"
                      image={
                        article.image.localFile.childImageSharp.gatsbyImageData
                      }
                      alt={article.image.title}
                    />
                  </div>
                </div>
                <div className="hidden xl:block">
                  <ZoomableImage
                    id="small"
                    image={
                      article.image.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={article.image.title}
                  />
                </div>
              </div>
            </div>
            <div className="article-content-container pb-[50px]">
              <div className="relative">
                <div className="article-content">
                  <div className="table-of-contents">
                    <div className="text-[#202135] font-bold text-[23px] mb-5">
                      TABLE OF CONTENTS
                    </div>
                    <ul>
                      <ContentfulRichTextContentTable
                        raw={article.text}
                        highlights={highlights}
                      />
                    </ul>
                  </div>
                  <div className="article-text float-left w-full xl:w-10/12 xl:pl-10">
                    <ContentfulRichText
                      raw={article.text}
                      adjustLink={finalAdjustLink}
                      openPopup={() => setShowDownloadPopup(true)}
                      adjustLinkUrlParamsToReplace={replaceHashed}
                    />
                    <div className="flex flex-col-reverse xl:flex-col">
                      {!page_version && (
                        <div className="my-10">
                          <BlogSharer
                            url={
                              typeof window !== "undefined"
                                ? window.location.href
                                : ""
                            }
                          />
                        </div>
                      )}
                      <BlogAuthor author={article.author} />
                    </div>
                  </div>
                  <div className="clear-both"></div>
                </div>
              </div>
            </div>
            {!!relatedArticle.edges.length && !page_version && (
              <RelatedArticles
                relatedArticles={relatedArticle.edges}
                linkPrefix="blog"
              />
            )}
          </div>
        </main>

        <Footer />
      </div>
    </>
  )
}

export default BlogArticleTemplate
