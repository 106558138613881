import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { Tooltip } from "react-tooltip";
import ContentfulRichText from "../../utilities/contentful-rich-text";

const DetailsTooltip = ({
  id,
  avatar,
  name,
  expertType,
  twitterProfileUrl,
  description,
}) => {
  return (
    <Tooltip
      globalCloseEvents={{
        'scroll': true,
      }}
      opacity={1}
      style={{
        backgroundColor: "white",
        color: "#222",
        borderRadius: "6px",
        filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, .35))",
        zIndex: 100,
        padding: "16px",
      }}
      anchorSelect={id}
      className="flex gap-4"
      place="bottom"
      clickable
    >
      {avatar && avatar.localFile &&
        <GatsbyImage
          className="w-[60px] h-[60px] rounded-[50%]"
          image={getImage(avatar.localFile.childImageSharp.gatsbyImageData)!}
          alt={name}
        />
      }
      <div className="flex flex-col gap-2">
        {name && <div className="text-2xl font-bold">{name}</div>}
        {!!expertType &&
          <div className="flex gap-2">
            {expertType}
            <StaticImage
              src="../../images/checkBlack.png"
              alt="aa"
              width={18}
              height={18}
            />
          </div>
        }
        <a
          className="w-fit"
          href={twitterProfileUrl?.twitterProfileUrl}
          target="_blank"
        >
          <StaticImage
            src="../../images/Twitter-X-icon.png"
            alt="aa"
            width={18}
            height={18}
          />
        </a>
        <ContentfulRichText
          className="w-[200px] text-[#5a5b65] text-[12px]"
          raw={description}
        />
      </div>
    </Tooltip>
  );
};

const AuthorFact = ({ author, updatedAt }) => {
  return (
    <div className="flex space-x-4">
      <GatsbyImage
        className="w-[60px] h-[60px] rounded-[50%]"
        image={
          getImage(author.avatar.localFile.childImageSharp.gatsbyImageData)!
        }
        alt={author.name}
      />
      <div className="text-[12px]">
        {author && (
          <div id="author-tooltip" className="font-bold w-fit text-[16px]">
            {author.name}
          </div>
        )}
        <div id="author-fact" className="author-fact">
          <div className="flex flex-wrap gap-2">
            {author.expertType}
            {!!author.fact && (
              <>
                {!!author.expertType && <span>|</span>}
                <span id="fact-tooltip" className="flex gap-2">
                  <div className="flex gap-2">
                    <StaticImage
                      src="../../images/check.png"
                      alt=""
                      width={18}
                      height={18}
                    />
                    Fact checked by <span className="font-bold">{author.fact.name}</span>
                  </div>
                </span>
              </>
            )}
          </div>
        </div>
        {!!author && <DetailsTooltip id={"#author-tooltip"} {...author} />}
        {!!author.fact && <DetailsTooltip id={"#fact-tooltip"} {...author.fact} />}
        <div className="mt-2 text-[14px]">Last Updated: {updatedAt}</div>
      </div>
    </div>
  );
};

export default AuthorFact;
