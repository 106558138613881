import { Link } from "gatsby"
import React from "react"
import { stringToPath } from "../../utilities/string-path-convertor"

const BlogBreadCrumbs = ({ article, clickable = true }) => {
  return (
    <div className='blog-article-breadcrumbs capitalize flex items-center space-x-3'>
      {clickable ? <Link to='/'>Home</Link> : <span>Home</span>}
      <span>/</span>
      {clickable ? <Link to='/blog/'>Blog</Link> : <span>Blog</span>}
      <span>/</span>
      {clickable ? (
        <Link to={`/blog/${stringToPath(article.category.name)}/`}>
          {article.category.name.toLowerCase()}
        </Link>
      ) : (
        <span>{article.category.name.toLowerCase()}</span>
      )}
    </div>
  )
}

export default BlogBreadCrumbs
