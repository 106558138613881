import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BlogArticleType } from "../../types/blog-types"
import ContentfulRichText from "../../utilities/contentful-rich-text"

const BlogAuthor = ({ author }: { author: BlogArticleType["author"] }) => {
  if (!author) return null

  return (
    <div className='blog-author text-center xl:text-left flex flex-col xl:flex-row space-y-4 xl:space-y-0 xl:space-x-8 my-5'>
      <div className='blog-author-avatar'>
        <GatsbyImage
          className="avatar-img"
          image={
            getImage(author.avatar.localFile.childImageSharp.gatsbyImageData)!
          }
          alt={author.name}
        />
      </div>
      <div className='blog-author-description'>
        <div className='text-[#202135] text-[16px] font-semibold'>
          {author.name}
        </div>
        <ContentfulRichText
          className='text-[#5a5b65] text-[16px]'
          raw={author.description}
        />
      </div>
    </div>
  )
}

export default BlogAuthor
